import React from 'react'

const Container: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children, className, ...other }) => {
  return (
    <div
      className={
        'mx-auto w-full px-[20px] py-0 md:container' +
        (className ? ` ${className}` : '')
      }
      {...other}
    >
      {children}
    </div>
  )
}

export default Container
